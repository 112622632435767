<template>
  <div ref="tree" class="mygoods_zong">
    <div class="head">
      <!-- 头部tab栏 -->
      <div class="hd_tab">
        <div class="head_item_click">审核商品</div>
      </div>
      <!-- 中间搜索框 -->
      <div class="hd_search">
        <div class="search_box">
          <div class="txt">商品名称/id</div>
          <input class="inp" v-model="shop_title" />
        </div>
        <div class="search_box">
          <div class="txt"></div>
          <el-button class="search_btn" @click="sub_search">搜索</el-button>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- 批量操作商品 -->
    <div class="goods_edit">
      <el-button
        class="edit_box"
        @click="all_audit(0)"
        :disabled="agree_Compensation"
        :class="{ edit_box1: agree_Compensation == false }"
      >
        审核通过
      </el-button>
      <el-button
        class="edit_box"
        @click="all_audit(1)"
        :disabled="agree_Compensation"
        :class="{ edit_box1: agree_Compensation == false }"
      >
        审核不通过
      </el-button>
      <div class="edit_hou">已选商品<span v-if="goods_len > 0">({{ goods_len }})</span></div>
    </div>
    <!-- 商品列表 -->
    <div class="kkk">
      <table class="goods_list">
        <!-- 表头 -->
        <thead class="list_hd">
          <th class="one">
            <el-checkbox
              class="checkbox"
              v-model="checkedAll"
              @change="handleCheckAllChange"
            >
              商品
            </el-checkbox>
          </th>
          <th class="it">价格</th>
          <th class="it">库存</th>
          <!-- <th class="it">销量</th> -->
          <th class="it">创建时间</th>
          <th class="it">操作</th>
        </thead>
        <!-- 表单内容 -->
        <tbody class="list_text">
          <tr
            v-for="(item, index) in shop_list"
            :key="index"
            class="tr"
            style="border: 1px solid #e8e8e8"
          >
            <td class="gds_item">
              <el-checkbox class="checkbox" v-model="item.v1" @change="hra($event,index)">
                <el-image
                  class="goods_tu"
                  :src="item.img_src"
                  :fit="'cover'"
                  :preview-src-list="item.img_list"
                  @click.prevent="a"
                ></el-image>
              </el-checkbox>
              <div class="goods_info">
                <div class="Trade_name">{{ item.title }}</div>
                <div class="goods_id">ID:{{ item.id }}</div>
                <div class="di">
                  <div class="type">{{ item.type_title }}</div>
                  <div class="type">{{ item.brand }}</div>
                  <div class="zhe" v-if="item.reduction == 1">折</div>
                  <div class="baoyou" v-if="item.freight == 0">包邮</div>
                </div>
              </div>
            </td>
            <td>
              <div class="price">￥{{ item.price }}</div>
            </td>
            <td>
              <div class="stock">{{ item.total }}</div>
            </td>

            <td>
              <div class="creat_time">
                <div class="time_txt">{{ item.create_time }}</div>
                <div class="goods_type">正在审核中</div>
              </div>
            </td>
            <td>
              <span
                class="form-btn btn-sm btn-normal"
                @click="btn_yaudit(item.id)"
                >审核通过</span
              >
              <span
                class="form-btn btn-sm btn-danger"
                @click="btn_audit(item.id)"
                >审核不通过</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <el-dialog
      class="bba"
      center
      custom-class="dialog"
      title="审核不通过"
      :visible.sync="reply_comment"
    >
      <div class="bba_main">
        <el-input
          type="textarea"
          :rows="7"
          placeholder="请输入不通过理由"
          v-model="textarea"
        >
        </el-input>
      </div>
      <div class="footer_btn">
        <el-button class="bt_cancel" @click="cancel_btn">取消</el-button>
        <el-button class="bt_confirm" @click="audit_nobtn">确定</el-button>
        <!-- 事件名已经在下面给定了-->
      </div>
    </el-dialog>
    <!-- 底部分页 -->
    <page-tab :page="page" :count="page_count" @change="change_page"></page-tab>
  </div>
</template>

<script>
import axios from "../../common/axios";
import pageTab from "../pages/index";

export default {
  components: {
    pageTab,
  },
  props: ["act"],
  data() {
    return {
      tab: 0, //选择宝贝状态
      goods_len:'0',
      value: "", // 订单状态选中的值
      checkedAll: false, //商品多选框
      agree_Compensation: true, // 控制头部按钮是否禁用
      page: 1,
      page_count: 1,
      shop_title: "",
      reply_comment: false, //弹窗
      textarea: "", //多行回复
      shop_list: [],
      shop_count: 0,
      on_shopid: "",
    };
  },
  mounted() {
    this.get_goods();
  },
  methods: {
    all_audit(type = 0) {
      if (this.agree_Compensation) {
        return;
      }
      let on_shop = this.shop_list.filter((item) => item.v1 == true);
      let on_arrid = [];
      on_shop.forEach((item) => {
        on_arrid.push(item.id);
      });
      let strid = on_arrid.toString();
      if (type == 1) {
        this.btn_audit(strid);
      } else {
        this.btn_yaudit(strid);
      }
    },
    btn_audit(id) {
      this.reply_comment = true;
      this.on_shopid = id;
    },
    cancel_btn() {
      // 回复评价弹框取消按钮事件
      this.on_shopid = "";
      this.reply_comment = false;
    },
    audit_nobtn() {
      let textarea = this.textarea;
      let sid = this.on_shopid;
      this.$confirm("商品确定要审核不通过吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (textarea == "") {
            this.$message.error("请输入不通过的原因");
            return;
          }
          this.cancel_btn();
          this.post_audit(sid, 1, textarea);
        })
        .catch(() => {});
    },
    sub_search() {
      this.page = 1;
      this.get_goods();
    },
    get_goods(page = 0) {
      if (page == 0) {
        page = 1;
      }
      let title = this.shop_title;
      let status = 3;
      this.page = page;
      let shop_list = [];
      let url = "/adminshop/goods/goods_list.html";
      let data = { page, title, status };
      axios.post(url, data).then((res) => {
        let success = res.success;
        let data = res.data;
        if (success) {
          let list = data.list;
          if (list != undefined && list != null && list != "") {
            for (var i in list) {
              shop_list[i] = list[i];
              shop_list[i].v1 = false;
            }
            // shop_list = list;
          }
          this.page_count = parseInt(data.pages);
        } else {
          console.log(res.error);
        }
        this.shop_list = shop_list;
      });
    },
    btn_yaudit(sid) {
      this.$confirm("商品确定要审核通过吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.post_audit(sid);
        })
        .catch(() => {});
    },
    post_audit(sid, type = 0, text = "") {
      let loading = this.$loading({
        text: "Loading",
      });
      let url = "/adminshop/goods/goods_audit.html";
      let data = { sid, type, text };
      axios.post(url, data).then((res) => {
        loading.close();
        let success = res.success;
        let data = res.data;
        if (success) {
          if (data.status == 1) {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.get_goods();
            this.checkedAll = false;
            this.agree_Compensation = true;
            this.goods_len = 0;
          } else {
            this.$alert(data.msg, "tip", {
              confirmButtonText: "confirm",
              type: "error",
            });
          }
        } else {
          this.$alert(data.msg, "tip", {
            confirmButtonText: "confirm",
            type: "error",
          });
        }
      });
    },
    change_page(e) {
      let page = e.value;
      this.page = page;
      this.get_goods(page);
      this.$refs.tree.scrollTop = 0 ;
       this.checkedAll = false;
      this.agree_Compensation = true;
      this.goods_len = 0;
    },
    show_ds() {},
    handleCheckAllChange(e) {
      this.checkedAll = e;
      if (this.checkedAll == true) {
        this.shop_list.forEach((item) => {
          this.$set(item, "v1", true);
          this.agree_Compensation = false;
          this.goods_len = this.shop_list.length;
        });
      } else {
        this.shop_list.forEach((item) => {
          this.$set(item, "v1", false);
          this.agree_Compensation = true;
          this.goods_len = 0;
        });
      }
    },
    hra(e,index) {
      //选中其中一个退款订单，触发相对应订单能操作的按钮
     this.shop_list[index].v1 = e;
      let a = this.shop_list.filter((item) => item.v1 == true).length;
      console.log(a);
      this.goods_len = a;
      if (a > 0) {
        this.agree_Compensation = false;
      } else {
        this.agree_Compensation = true;
      }
      if (a == this.shop_list.length) {
        this.checkedAll = true;
      } else {
        this.checkedAll = false;
      }
    },
	a(){},
  },
};
</script>

<style scoped>
@import "./commodity_audit.css";
</style>
